import React from 'react';
import styles from './MediaLink.module.css';

export default ({
  children, href, date, source, sourceColor,
}) => (
  <a target="_blank" rel="noopener noreferrer" className={styles.mediaLink} href={href}>
    <div className={styles.mediaLinkBox}>
      <span className={styles.date}>{date}</span>
      <h4>{children}</h4>
      <div className={styles.source} style={{ color: sourceColor || 'black' }}>
        {source}
      </div>
    </div>
  </a>
);
