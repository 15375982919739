import React from 'react';
import cx from 'classnames';
import { i18n } from '../../utils/i18n';
import SectionTitle from '../SectionTitle';
import styles from './ContactForm.module.css';

/**
 * This stateful component contains the logic required to submit a contact form
 * to netlify's contact form service.
 */
class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formStatus: null,
    };

    // This binding is necessary to make `this` work in the callback
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  /**
   * Validates the form and then sends the message
   *
   * @param e event
   */
  handleSubmit(e) {
    e.preventDefault();
    // eslint-disable-next-line no-undef
    const formData = new FormData(e.target);

    // Validate all the inputs
    if (formData.get('name') && formData.get('email') && formData.get('message')) {
      // Set the status to sending
      this.setState({ formStatus: 'Sending' });

      // eslint-disable-next-line no-undef
      fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: new URLSearchParams(formData).toString(),
      })
        .then((res) => this.setState({
          // If successfully sent, set status to success
          formStatus: Math.floor(res.status / 100) === 2 ? 'Success' : 'Error',
        }))
        .catch(() => this.setState({ formStatus: 'Error' }));
    } else {
      this.setState({ formStatus: 'Error' });
    }
  }

  render() {
    const { locale, children, title } = this.props;
    const { formStatus } = this.state;
    return (
      <div className="outerWrap">
        <div className={styles.contact}>
          <div className={styles.contactSidebar}>
            <SectionTitle>{title}</SectionTitle>
            {children}
          </div>
          <form
            className={styles.form}
            name="contact"
            method="POST"
            data-netlify="true"
            onSubmit={this.handleSubmit}
          >
            <input type="hidden" name="form-name" value="contact" />
            <div className={styles.inputFields}>
              <label htmlFor="name" className={styles.name}>
                {i18n(locale, 'contactName')}
                <input type="text" id="name" name="name" required />
              </label>

              <label htmlFor="email" className={styles.email}>
                {i18n(locale, 'contactEmail')}
                <input type="email" id="email" name="email" required />
              </label>

              <label htmlFor="message" className={styles.message}>
                {i18n(locale, 'contactMessage')}
                <textarea id="message" name="message" required />
              </label>

              <div className={cx(styles.status, styles[formStatus])}>
                {i18n(locale, `contact${formStatus}`)}

                <button className={styles.submit} type="submit">
                  {i18n(locale, 'contactSend')}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default ContactForm;
