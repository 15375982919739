/* eslint-disable react/no-array-index-key */

import React from 'react';
import styles from './LogoSpread.module.css';

export default ({ logos, title }) => (
  <div className={styles.logoSpread}>
    <h3 className={styles.title}>{title}</h3>
    <div className={styles.logos}>
      {logos && logos.map((logo, i) => (
        <img
          className={styles.logo}
          src={logo}
          key={`${logo}-${i}`}
          alt=""
        />
      ))}
    </div>
  </div>
);
