import React from 'react';
import cx from 'classnames';
import styles from './Banner.module.css';

export default ({
  src, mobileSrc, alt, href, gradient,
}) => (
  (href)
    ? (
      <a className={cx(styles.full, gradient && styles.gradient)} href={href}>
        {mobileSrc ? (
          <>
            <img className={styles.desktop} src={src} alt={alt} />
            <img className={styles.mobile} src={mobileSrc} alt={alt} />
          </>
        ) : (
          <img src={src} alt={alt} />
        )}
      </a>
    )
    : (
      <div className={cx(styles.full, gradient && styles.gradient)}>
        {mobileSrc ? (
          <>
            <img className={styles.desktop} src={src} alt={alt} />
            <img className={styles.mobile} src={mobileSrc} alt={alt} />
          </>
        ) : (
          <img src={src} alt={alt} />
        )}
      </div>
    )
);
