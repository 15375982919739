import React from 'react';
import FullSection from '../FullSection';
import styles from './FullBlockLink.module.css';

export default ({ children, href }) => (
  <FullSection className={styles.linkWrap}>
    <a className={styles.link} href={href}>
      {children}
    </a>
  </FullSection>
);
