import React from 'react';
import FullSection from '../FullSection';
import styles from './Subscribe.module.css';

const Subscribe = () => (
  <FullSection className={styles.subscribe}>
    <iframe
      title="Substack Signup Form"
      src="https://ghostislandme.substack.com/embed"
      width="480"
      height="320"
      frameBorder="0"
      scrolling="no"
    />
  </FullSection>
);

export default Subscribe;
