import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { timeFormat } from 'd3-time-format';
import { i18n } from '../../utils/i18n';
import FullSection from '../FullSection';
import InlinePagination from '../InlinePagination';
import styles from './NewsIndex.module.css';

export default ({ locale }) => (
  <StaticQuery
    query={graphql`
      {
        components: allFile(filter: { sourceInstanceName: {eq: "news"} }) {
          edges {
            node {
              childMdx {
                fields {
                  page
                  locale
                }
                frontmatter {
                  title
                  publishDate
                  description
                }
              }
            }
          }
        }
      }
    `}
    render={({ components: { edges } }) => {
      const news = edges.map((d) => ({
        ...d.node.childMdx.fields,
        ...d.node.childMdx.frontmatter,
        publishDate: new Date(d.node.childMdx.frontmatter.publishDate),
      }));

      const currentLocaleNews = news.filter((d) => d.locale === locale);

      // eslint-disable-next-line no-restricted-globals
      const isValidDate = (d) => d instanceof Date && !isNaN(d);

      const newsStories = [
        ...currentLocaleNews,
        ...news.filter((d) => !currentLocaleNews.find((e) => e.page === d.page)),
      ]
        .map((d) => ({ ...d, publishDate: isValidDate(d.publishDate) ? d.publishDate : null }))
        .sort((a, b) => {
          // If publishdate is invalid treat it as pinned.
          if (!b.publishDate) return 1;
          if (!a.publishDate) return -1;
          return b.publishDate - a.publishDate;
        });

      return (
        <FullSection>
          <InlinePagination buttonText={i18n(locale, 'newsMoreStories')}>
            {newsStories.map((story) => (
              <a className={styles.newsStory} key={story.title} href={`/news/${story.page}`}>
                {story.publishDate && <span>{timeFormat('%Y-%m-%d')(story.publishDate)}</span>}
                <h3>{story.title}</h3>
                {story.description && (<p className={styles.description}>{story.description}</p>)}
                <div className={styles.newsMore}>{i18n(locale, 'newsMore')}</div>
              </a>
            ))}
          </InlinePagination>
        </FullSection>
      );
    }}
  />
);
