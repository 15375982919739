import React, { useRef, useEffect, useState } from 'react';
import cx from 'classnames';
import FullSection from '../FullSection';
import SectionTitle from '../SectionTitle';
import Arrow from '../Icons/Arrow';
import styles from './ShowCarousel.module.css';

const useResize = (myRef) => {
  const [innerWidth, setInnerWidth] = useState(0);
  const [width, setWidth] = useState(0);
  const [left, setLeft] = useState(0);

  useEffect(() => {
    const handleUpdate = () => {
      setInnerWidth(myRef.current.scrollWidth);
      setWidth(myRef.current.clientWidth);
      setLeft(myRef.current.scrollLeft);
    };

    if (myRef.current) handleUpdate();

    // eslint-disable-next-line no-undef
    window.addEventListener('resize', handleUpdate);
    myRef.current.addEventListener('scroll', handleUpdate);

    return () => {
      // eslint-disable-next-line no-undef
      window.removeEventListener('resize', handleUpdate);
      myRef.current.removeEventListener('scroll', handleUpdate);
    };
  }, [myRef]);

  return { width, innerWidth, left };
};

export default ({
  children, title, allShows, href,
}) => {
  const carouselRef = useRef();
  const { width, innerWidth, left } = useResize(carouselRef);

  const handleClick = (delta) => (e) => {
    e.preventDefault();
    carouselRef.current.scrollLeft += delta;
  };

  return (
    <FullSection className={styles.wrapper}>
      {title && (
        <div className={styles.titleSection}>
          <a className={styles.titleLink} href={href}>
            <SectionTitle size="h3">{title}</SectionTitle>
          </a>
        </div>
      )}

      <div className={styles.carouselWrap}>

        <button
          type="button"
          className={cx(styles.scrollButton, styles.left)}
          onClick={handleClick(-200)}
        >
          <Arrow stroke={(left) ? 'white' : null} direction="left" />
        </button>

        <div ref={carouselRef} className={styles.carousel}>
          {children}
        </div>

        <button
          type="button"
          className={cx(styles.scrollButton, styles.right)}
          onClick={handleClick(200)}
        >
          <Arrow stroke={((innerWidth - left) > width) ? 'white' : null} direction="right" />
        </button>

      </div>

      {allShows && (
        <div className={styles.allShowsLinkWrap}>
          <a className={styles.allShowsLink} href="/all-shows">
            {allShows}
          </a>
        </div>
      )}

    </FullSection>
  );
};
