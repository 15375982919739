import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';
import Grid from '../Grid';
import LogoSpread from '../LogoSpread';
import styles from './Brandbox.module.css';

const comps = {
  Textbox: ({ title, children }) => (
    <div className={styles.textBox}>
      <div className={styles.textBoxTitleWrap}>
        <div className={styles.textBoxTitle}>
          <h3>{title}</h3>
        </div>
      </div>
      {children}
    </div>
  ),
  LogoSpread: (props) => (
    <div className={styles.logoWrapper}>
      <LogoSpread {...props} />
    </div>
  ),
};

export default ({ locale }) => (
  <StaticQuery
    query={graphql`
      {
        components: allFile(filter: {
          sourceInstanceName: {eq: "components"}, 
          childMdx: {frontmatter: {component: {eq: "brandbox"}}}
        }) {
          edges {
            node {
              childMdx {
                body
                fields {
                  locale
                }
              }
            }
          }
        }
      }
    `}
    render={({ components }) => {
      const { body } = components.edges.find(
        (d) => d.node.childMdx.fields.locale === locale,
      ).node.childMdx;

      return (
        <Grid
          backgroundColor="white"
          className={styles.brandBox}
          columns={2}
          mobileColumns={1}
        >
          <MDXProvider components={comps}>
            <MDXRenderer>
              {body}
            </MDXRenderer>
          </MDXProvider>
        </Grid>
      );
    }}
  />
);
