import React from 'react';
import FullSection from '../FullSection';
import HighlightTitle from '../HighlightTitle';
import styles from './Testimonials.module.css';

export default ({ children, title }) => (
  <FullSection className={styles.testimonialsFull}>
    <div className={styles.container}>
      <div className={styles.title}>
        <HighlightTitle title={title} />
      </div>
      <div className={styles.testimonialsWrap}>
        {children}
      </div>
    </div>
  </FullSection>
);
