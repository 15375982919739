import * as React from 'react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';
import Layout from '../../components/Layout';
import PageHeader from '../../components/PageHeader';
import Grid from '../../components/Grid';
import GridCard from '../../components/Grid/GridCard';
import ShowsSection from '../../components/FullSection';
import ShowCarousel from '../../components/ShowCarousel';
import SocialIcon from '../../components/SocialIcon';
import SocialPlatforms from '../../components/SocialPlatforms';
import Subscribe from '../../components/Subscribe';
import Brandbox from '../../components/Brandbox';
import SectionTitle from '../../components/SectionTitle';
import Testimonials from '../../components/Testimonials';
import Testimonial from '../../components/Testimonials/Testimonial';
import LogoSpread from '../../components/LogoSpread';
import ContactForm from '../../components/ContactForm';
import NewsIndex from '../../components/NewsIndex';
import MediaLink from '../../components/MediaLink';
import FullBlockLink from '../../components/FullBlockLink';
import Banner from '../../components/Banner';
import InlinePagination from '../../components/InlinePagination';
import PageContext from './pageContext';

// Components we are passing in as shortcode options to mdx files
const components = {
  PageHeader,
  Grid,
  GridCard,
  ShowCarousel,
  ShowsSection,
  Subscribe,
  Testimonials,
  Testimonial,
  LogoSpread,
  Brandbox,
  ContactForm,
  NewsIndex,
  MediaLink,
  SectionTitle,
  SocialIcon,
  SocialPlatforms,
  FullBlockLink,
  Banner,
  InlinePagination,
};

export default ({ pageContext }) => (
  <Layout pageContext={pageContext}>
    <PageContext.Provider value={pageContext}>
      <MDXProvider components={components}>
        <MDXRenderer>
          {pageContext.body}
        </MDXRenderer>
      </MDXProvider>
    </PageContext.Provider>
  </Layout>
);
