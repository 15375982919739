import React, { useContext } from 'react';
import cx from 'classnames';
import styles from './PageHeader.module.css';
import SectionTitle from '../SectionTitle';
import PageContext from '../../routes/page/pageContext';

export default ({ children }) => {
  const { title } = useContext(PageContext);

  // Separate out children that are social icons from content
  const filterSocialIcons = (d) => d.props && d.props.mdxType === 'SocialPlatforms';
  const socialIcons = (typeof children === 'object') && children.filter(filterSocialIcons);
  const restContent = (typeof children === 'object') && children.filter((d) => !filterSocialIcons(d));

  return (
    <div className={cx(styles.pageHeader, children && styles.pageHeaderDualCol)}>
      <div>
        <SectionTitle>
          {title}
        </SectionTitle>
        <div className={styles.socialWrap}>
          {socialIcons}
        </div>
      </div>

      {children && (
        <div className={styles.content}>
          {restContent}
        </div>
      )}
    </div>
  );
};
